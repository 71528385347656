@font-face {
  font-family: 'Roboto-bold';
  src: url('../public/Fonts/Roboto-Bold.ttf') format('truetype'); /* TTF format for bold */
}

@font-face {
  font-family: 'Roboto-regular';
  src: url('../public/Fonts/Roboto-Regular.ttf') format('truetype'); /* TTF format for regular */
}

@font-face {
  font-family: 'Roboto-medium';
  src: url('../public/Fonts/Roboto-Medium.ttf') format('truetype'); /* TTF format for medium */
}

@font-face {
  font-family: 'Roboto-light';
  src: url('../public/Fonts/Roboto-Light.ttf') format('truetype'); /* TTF format for medium */
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/Fonts/Roboto.ttf') format('truetype'); /* TTF format for medium */
}

@font-face {
  font-family: 'Exo-Black';
  src: url('../public/Fonts/Exo-Black.ttf') format('truetype'); /* TTF format for medium */
}

html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

input {
  outline: none;
}

.hidden {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}